import React from "react";
import styled from "styled-components";
import { mobileSize } from "../../../util/variables";
import { AppButton, AppText, FlexDiv } from "../../ui";
import { ContinueButton } from "./HappinessQuestions";

interface Props {
  quote: string;
  author: string;
  date: string;
  icon: string;
  background: string;
  handleNext: (answer: string | string[] | undefined) => void;
}

export const QuoteBreak: React.FC<Props> = ({
  quote,
  author,
  date,
  icon,
  background,
  handleNext,
}) => {
  return (
    <Container>
      <Circle background={background}>
        <AppText fontSize={20} mobileFontSize={17} fontWeight={700} textAlign="center">
          {quote}
        </AppText>
        <FlexDiv align="center" gap={10}>
          <img src={icon} alt="headshot of person" width={64} height={64} />
          <AuthorInfo>
            <AppText fontSize={18} mobileFontSize={18} style={{ margin: 0 }}>
              {author}
            </AppText>
            <AppText fontSize={14} mobileFontSize={14} style={{ margin: 0 }}>
              {date}
            </AppText>
          </AuthorInfo>
        </FlexDiv>
      </Circle>
      <ContinueButton onClick={() => handleNext(undefined)}>Continue</ContinueButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  width: 100%;
  padding-bottom: 40px;
`;

const Circle = styled.div<{ background: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 542px;
  height: 542px;
  padding: 72px;
  padding-bottom: 28px;
  border-radius: 50%;
  background: ${props => props.background};
  @media ${mobileSize} {
    width: 439px;
    height: 439px;
    padding-left: 64px;
    padding-right: 64px;
  }
`;

const AuthorInfo = styled.div`
  position: relative;
  margin-left: 20px;
  ::before {
    content: "—";
    position: absolute;
    left: -20px;
    top: 0;
    width: 5px;
    height: 5px;
  }
`;

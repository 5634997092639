import * as React from "react";
import styled from "styled-components";
import theme from "../../../util/theme";

type Props = {
  el: string;
  width: string;
  height: string;
  index: number;
  selected: boolean;
  icon: string | null;
  handleClick: (index: number) => void;
};

export const MultiSelectAnswer = ({
  el,
  width,
  height,
  index,
  icon,
  selected,
  handleClick,
}: Props) => {
  return (
    <MultiAnswer width={width} height={height} key={index} selected={selected} hasIcon={!!icon}>
      <MultiCheckBoxInput>
        <input type="checkbox" value={el} checked={selected} onChange={e => handleClick(index)} />
        <CustomCheckbox />
      </MultiCheckBoxInput>

      <TextContainer hasIcon={!!icon}>
        {icon && (
          <IconContainer>
            <img src={icon} alt="icon" />
          </IconContainer>
        )}
        <p>{el}</p>
      </TextContainer>
    </MultiAnswer>
  );
};

type MultiAnswerProp = {
  width: string;
  height: string;
  selected: boolean;
  hasIcon: boolean;
};

const MultiAnswer = styled.label<MultiAnswerProp>`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  /* height: ${props => props.height}; */
  /* width: ${props => props.width}; */
  min-height: 48px;
  max-height: 64px;
  height: 7vh;
  width: ${props => props.hasIcon ? "95%" : "45%"};
  border-radius: 8px;
  border: 2px solid #d2d5d8;
  border-color: ${props => (props.selected ? theme.SELECTED_BORDER_COLOR : "#d2d5d8")};
  background: ${props =>
    props.selected
      ? theme.SELECTED_COLOR
      : "#fafafa"};

  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 8px;
  cursor: pointer;

  transition: all 0.2s;
  
  @media screen and (min-width: 800px) {
    :hover {
      background: ${props => props.selected ? theme.SELECTED_COLOR : theme.HOVER_COLOR};
      border-color: ${props => props.selected ? theme.SELECTED_BORDER_COLOR : "transparent"};
    }
  }

  :active {
    background: ${theme.ACTIVE_COLOR};
  }
`;

export const MultiCheckBoxInput = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ span {
    border-color: ${theme.SELECTED_BORDER_COLOR};
    &::before {
      opacity: 1;
      height: 14px;
      width: 14px;
      top: 1px;
      left: 1px;
    }
  }
`;

export const CustomCheckbox = styled.span`
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: 14px;
  border: 2px solid #d2d5d8;
  border-radius: 3px;
  background-color: ${theme.WHITE_COLOR};

  &::before {
    content: "";
    position: absolute;
    top: 8px;
    left: 8px;
    height: 0px;
    width: 0px;
    border-radius: 1px;

    background-color: ${theme.SELECTED_BORDER_COLOR};
    opacity: 0;
    transition-property: height, width, top, left;
    transition-duration: 0.15s; 
  }
`;

const TextContainer = styled.div<{ hasIcon: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.hasIcon ? "auto" : "100%"};
  margin: auto;
  margin-left: ${props => (!props.hasIcon ? "34px" : "auto")};
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #292032;
  p {
    margin: 0;
    width: 100%;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  height: 23px;
  width: 35px;
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`;

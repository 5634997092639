import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";
import { AppButton } from "../../ui";
import { StyledInput } from "../../ui/StyledInput";
import { ContinueButton } from "./HappinessQuestions";

interface Props {
  handleNext: (answer: string | string[] | undefined) => void;
}

export const TextInputAnswer: React.FC<Props> = ({ handleNext }) => {
  const [value, setValue] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleNext(value);
  };

  return (
    <AnswersContainer onSubmit={handleSubmit}>
      <StyledInput name="email" value={value} type="email" onChange={setValue}/>
      <MarginDiv>
        <ContinueButton disabled={!value} type="submit">
          Next
        </ContinueButton>
      </MarginDiv>
    </AnswersContainer>
  );
};

const AnswersContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-top: 40px;
`;

const MarginDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 48px;
  @media ${mobileSize} {
    margin-top: 24px;
  }
`;

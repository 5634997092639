import React, { useState } from "react";
import styled from "styled-components";
// import { mobileSize } from "../../util/variables";
import theme from "../../util/theme";
import { mobileSize } from "../../util/variables";

interface Props {
  name: string;
  value: any;
  onChange: any;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  onBlur?: any;
  onFocus?: any;
}

export const StyledInput: React.FC<Props> = ({ name, value, onChange, placeholder, type, disabled, ...rest }) => {
  const [focused, setFocused] = useState(false);

  return (
    <AllInputContainer>
      <InputContainer
        $hasValue={!!value}
        focused={focused}
        disabled={disabled}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        <Label $hasValue={!!value}>{placeholder ?? name}</Label>
        <Input
          {...rest}
          placeholder={placeholder ?? name}
          type={type ?? "text"}
          $hasValue={!!value}
          disabled={disabled}
          autoComplete="on"
          value={value}
          onChange={e => onChange(e.target.value)}
        />
      </InputContainer>
      {/* <ErrorContainer>
        <ErrorMessage name={name} component="div" />
      </ErrorContainer> */}
    </AllInputContainer>
  );
};

const AllInputContainer = styled.div`
  width: 486px;
  display: flex;
  flex-direction: column;

  @media ${mobileSize} {
    width: 100%;
  }
`;

const ErrorContainer = styled.div`
  height: 8px;
  margin: 0;
  margin-bottom: 2rem;
  margin-left: 2px;
  color: red;
  font-size: 10px;
`;

type StyledLabelProps = {
  $hasValue: boolean;
  focused?: boolean;
  disabled?: boolean;
};

const InputContainer = styled.div<StyledLabelProps>`
  display: flex;
  flex-direction: column;

  width: inherit;
  height: 64px;
  border: 2px solid
    ${props =>
      props.disabled
        ?  "#D2D5D8"
        : props.focused
        ? theme.PRIMARY_COLOR
        : props.$hasValue
        ? theme.SLATE_COLOR_3
        : theme.LIGHT_GREY};
  border-radius: 8px;
  background: ${props => (props.disabled ? theme.LIGHT_GREY : theme.WHITE_COLOR)};
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
`;

const Label = styled.label<StyledLabelProps>`
  opacity: ${props => (props.$hasValue ? 1 : 0)};
  /* margin-bottom: -15px; */
  padding-top: 4px;
  padding-left: 16px;
  color: ${theme.SLATE_COLOR_2};
  font-size: 11px;
  font-family: ${theme.PRIMARY_FONT};
  text-transform: capitalize;

  transform: ${props => (props.$hasValue ? "" : "translateY(10px)")};
  transition: all 0.2s ease-out;
`;

const Input = styled.input<StyledLabelProps>`
  /* height: ${props => (props.$hasValue ? "90%" : "100%")}; */
  position: relative;
  top: -2px;
  width: inherit;
  padding: 8px 16px;
  padding-top: ${props => (props.$hasValue ? 0 : 8)}px;
  border: none;
  background: none;
  outline: none;
  font-size: 13px;

  transform: ${props => (props.$hasValue ? "" : "translateY(-16px)")};
  transition: all 0.2s ease-in-out;

  :active {
    border: none;
    outline: none;
  }

  ::placeholder {
    color: ${theme.SLATE_COLOR_2};
    text-transform: capitalize;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    // Microsoft edge
    text-transform: capitalize;
    color: ${theme.SLATE_COLOR_2};
  }
  // Overrides Safari disabled styling
  -webkit-text-fill-color: ${props => props.disabled ? "#716B78" : "initial"};
  opacity: 1;
`;

import React, { useEffect } from "react";
import { HappinessQuestions } from "../../components/PageSegments/happinessOnboarding/HappinessQuestions";
import { HappinessLayout } from "../../layouts/BasicLayout";
import { imprintOnboardingData } from "../../content/assets/imprintQuestions";
import { onboarding_questions_start } from "../../services/mixpanel/mixpanel";
import { storeUTMCookies } from "../../util/misc";

export default ({ data, location }) => {
  const { search } = location;

  useEffect(() => {
    // onboarding_questions_start();
    // if (search) {
    //   storeUTMCookies(search);
    // }
  });

  return (
    <HappinessLayout>
      <HappinessQuestions data={imprintOnboardingData}/>
    </HappinessLayout>
  );
};

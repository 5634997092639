import React from "react";
import styled from "styled-components";
import { mobileSize } from "../../../util/variables";
import { AppButton, AppText } from "../../ui";
import { ContinueButton } from "./HappinessQuestions";

interface Props {
  text: string;
  image: string;
  handleNext: (answer: string | string[] | undefined) => void;
}

export const TransitionBreak: React.FC<Props> = ({ text, image, handleNext }) => {
  return (
    <Container>
      <Img src={image} alt="imprint image" />
      <AppText textAlign="center" fontSize={18} mobileFontSize={14} style={{ maxWidth: 588 }}>
        {text}
      </AppText>
      <ContinueButton onClick={() => handleNext(undefined)}>Continue</ContinueButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  width: 100%;
  padding-bottom: 40px;
  @media ${mobileSize} {
    gap: 20px;
  }
`;

const Img = styled.img`
  min-height: 321px;
  @media ${mobileSize} {
    min-height: 200px;
    max-width: 75%;
  }
`;

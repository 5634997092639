import { JohnIcon, StateOfMind, Chart, KevinIcon, JaneyIcon, SeanIcon } from "./imprint";

export const imprintOnboardingData = [
  {
    question: "On a scale from 1-5, how happy are you with your life right now?",
    answer_type: "select",
    answers: [
      "1 (Very unhappy)",
      "2 (Somewhat unhappy)",
      "3 (Neither happy nor unhappy)",
      "4 (Somewhat happy)",
      "5 (Very happy)",
    ],
    answer_key: "happinessLevel",
    // conditional_question: {
    //   condition: "1 - Very rarely happy",
    //   data: {
    //     question: "",
    //     answer_type: "boolean",
    //     answers: ["A trip to Disney would increase your current level of happiness."],
    //     answer_key: "disneyHappiness",
    //   },
    // },
  },
  {
    question: "On a scale of 1-5, how would  you rate your physical health?",
    answer_type: "select",
    answers: [
      "1 (Very unhealthy)",
      "2 (Unhealthy)",
      "3 (Neither healthy nor unhealthy)",
      "4 (Healthy)",
      "5 (Very healthy)",
    ],
    answer_key: "physicalHealth",
  },
  {
    question: "How often do you feel excited and interested in things?",
    answer_type: "select",
    answers: ["All of the time", "Most of the time", "Occasionally", "Rarely", "Never"],
    answer_key: "interestedInThings",
  },
  {
    question: "Agree or disagree?",
    subtext: "“I lead a purposeful and meaningful life.”",
    answer_type: "select",
    answers: ["Strongly agree", "Agree", "Neutral", "Disagree", "Strongly disagree"],
    answer_key: "leadMeaningfulLife",
  },
  {
    question: "Agree or disagree?",
    subtext: "“I awaken with a sense of excitement about the day’s possibilities.”",
    answer_type: "select",
    answers: ["Strongly agree", "Agree", "Neutral", "Disagree", "Strongly disagree"],
    answer_key: "senseOfExcitement",
  },
  {
    answer_type: "quote",
    answers: [
      "“The course is structured really well: 20 minutes a day for seven consective days. I learned a ton about the science of happiness and actually applied what I learned to my own life during every session.”",
      "John",
      "June 2022 Imprint Cohort",
    ],
    assets: [JohnIcon, "linear-gradient(211.12deg, #FFDA6E 0.94%, #FFE699 113.68%)"],
  },
  {
    question: "These next questions are about your feelings and state of mind.",
    answers: [
      "Your answers are confidential and will help us personalize your 7-day experience and connect you with the right personal instructor.",
    ],
    answer_type: "transition",
    assets: [StateOfMind],
  },
  {
    question: "In an average week, how often do you feel joyful?",
    answer_type: "select",
    answers: ["Every day", "A few times a week", "Once a week or less", "Very rarely"],
    answer_key: "feelJoyful",
  },
  {
    question: "In an average week, how often do you feel angry?",
    answer_type: "select",
    answers: ["Every day", "A few times a week", "Once a week or less", "Very rarely"],
    answer_key: "feelAngry",
  },
  {
    question: "In an average week, how often do you feel lonely?",
    answer_type: "select",
    answers: ["Every day", "A few times a week", "Once a week or less", "Very rarely"],
    answer_key: "feelLonely",
  },
  {
    question: "Agree or disagree?",
    subtext: "“I experience deep emotions when I see beautiful things.”",
    answer_type: "select",
    answers: ["Strongly agree", "Agree", "Neutral", "Disagree", "Strongly disagree"],
    answer_key: "deepEmotionsBeautifulThings",
  },
  {
    answer_type: "text",
    answers: [
      "<b>So what is imprint?</b> Imprint is a 7-day personalized happiness course where you work with an expert instructor, learn about the science of happiness, and apply new daily habits to your life.",
    ],
  },
  {
    answer_type: "quote",
    answers: [
      "“Self-help books make it sound easy to get happier, but I never actually did any of the exercises. Imprint actually worked because I was held accountable for those 20 minutes each day, completing exercises and building new habits. I would never have done it on my own.”",
      "Janey",
      "May 2022 Imprint Cohort",
    ],
    assets: [
      JaneyIcon,
      "linear-gradient(180deg, rgba(168, 171, 255, 0.79) 25%, rgba(168, 171, 255, 0) 100%)",
    ],
  },
  {
    question: "Agree or disagree?",
    subtext: "“I am an extremely grateful person.”",
    answer_type: "select",
    answers: ["Strongly agree", "Agree", "Neutral", "Disagree", "Strongly disagree"],
    answer_key: "gratefulPerson",
  },
  {
    question: "Agree or disagree?",
    subtext: "“I can always find the positive in what seems negative to others.”",
    answer_type: "select",
    answers: ["Strongly agree", "Agree", "Neutral", "Disagree", "Strongly disagree"],
    answer_key: "findPositivity",
  },
  {
    question: "Agree or disagree?",
    subtext: "“It is easy for me to stay disciplined.”",
    answer_type: "select",
    answers: ["Strongly agree", "Agree", "Neutral", "Disagree", "Strongly disagree"],
    answer_key: "disciplined",
  },
  {
    question: "Agree or disagree?",
    subtext: "“I rarely hold a grudge.”",
    answer_type: "select",
    answers: ["Strongly agree", "Agree", "Neutral", "Disagree", "Strongly disagree"],
    answer_key: "rarelyHoldGrudge",
  },
  {
    answer_type: "text",
    answers: [
      "With Imprint, you <b>schedule your sessions in advance</b>, and your instructor will <b>hold you accountable</b> for completing them. If you skip one, you’ll get locked out of the course and need to work with your instructor to adjust your course schedule.",
    ],
  },
  {
    answer_type: "text",
    answers: [
      "It can sound scary, but <b>being held accountable is the best way to actually follow through on your goals.</b><br/><br/>We’re so confident in this approach that, if you complete the course and don't feel happier, we give you your money back.",
    ],
  },
  {
    question: "Agree or disagree?",
    subtext:
      "“I usually feel that I have a sense of direction in my life.”",
    answer_type: "select",
    answers: ["Strongly agree", "Agree", "Neutral", "Disagree", "Strongly disagree"],
    answer_key: "senseOfDirection",
  },
  {
    question: "Agree or disagree?",
    subtext:
      "“Despite challenges, I always remain hopeful about the future.”",
    answer_type: "select",
    answers: ["Strongly agree", "Agree", "Neutral", "Disagree", "Strongly disagree"],
    answer_key: "remainHopefulAboutFuture",
  },
  {
    question: "How often do you lose track of time while doing something you enjoy?",
    answer_type: "select",
    answers: ["Every day", "A few times a week", "Once a week or less", "Very rarely"],
    answer_key: "loseTrackOfTimeWhileEnjoying",
  },
  {
    question: "How often do you feel like you could be doing something more productive with your time?",
    answer_type: "select",
    answers: ["Every day", "A few times a week", "Once a week or less", "Very rarely"],
    answer_key: "couldBeMoreProductive",
  },
  {
    question: "Agree or disagree?",
    subtext:
      "“If I could build better habits, I would be happy more often.”",
    answer_type: "select",
    answers: ["Strongly agree", "Agree", "Neutral", "Disagree", "Strongly disagree"],
    answer_key: "happierIfBuildBetterHabits",
  },
  {
    question: "We’re analyzing your responses to put together your 7-day course...",
    answer_type: "progress",
  },
  {
    answer_type: "quote",
    answers: [
      "“Accountability really works. You have to complete the daily lesson or you get locked out of the course. So I did it every day, even when I would never have chosen to do it on my own, and the consistency had a really big impact on my mood.”",
      "Sean",
      "April 2022 Imprint Cohort",
    ],
    assets: [SeanIcon, "linear-gradient(211.12deg, #FFDA6E 0.94%, #FFE699 113.68%)"],
  },
  {
    question: "Based on your answers, we’ve created a personalized 7-day course focused on increasing your happiness.",
    answer_type: "chart2",
    answers: [
      "“Now I get why they offer the money-back guarantee. When you’re actually being held accountable and consistently putting in the effort, it really makes a difference.”",
      "Kevin",
      "April 2022 Imprint Cohort",
    ],
    assets: [Chart, KevinIcon],
  },
];

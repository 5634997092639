import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { delay } from "../../../util/misc";

type Props = {
  answers: string[];
  handleClick: (e: any) => void;
};

export const BooleanTypeAnswer = ({ answers, handleClick }: Props) => {
  const [clicked, setClicked] = useState([false, false]);

  useEffect(() => {
    if (clicked[0] || clicked[1]) {
      delay(100).then(() => {
        const value = clicked[0] ? "Yes" : "No";
        handleClick(value);
      });
    }
  }, [clicked, handleClick]);

  const handleAnswerClick = (index: number) => {
    const clickedState = clicked.slice().map((el, i) => {
      if (i === index) {
        return !el;
      }

      return el;
    });
    setClicked(clickedState);
  };

  return (
    <BooleanContainer>
      <BooleanQuestionContainer>
        <TextContainer src={answers[0]} />
      </BooleanQuestionContainer>
      <BooleanAnswerContainer>
        <BooleanAnswer clicked={clicked[0]} onClick={() => handleAnswerClick(0)} >
          <b>✓ </b> Yes
        </BooleanAnswer>
        <BooleanAnswer clicked={clicked[1]} onClick={() => handleAnswerClick(1)}>
          <b>✕</b> No
        </BooleanAnswer>
      </BooleanAnswerContainer>
    </BooleanContainer>
  );
};

const BooleanContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  max-width: 90%;
`;

const BooleanQuestionContainer = styled.div`
  margin-bottom: 48px;
  min-width: 340px;
  width: 100%;
  height: 230px;
`;

const TextContainer = styled.img`
  display: block;
  margin: auto;
  width: 90%;
`;

export const BooleanAnswerContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  min-width: 140px;
  width: 100%;
`;

export const BooleanAnswer = styled.div<{ clicked: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 45%;

  border: 2px solid #d2d5d8;
  border-color: ${props => (props.clicked ? "#5095EB66" : "#d2d5d8")};
  border-radius: 12px;

  font-size: 18px;
  font-weight: 600;
  text-align: center;
  
  background: ${props =>
    props.clicked
      ? "linear-gradient(0deg,rgba(80, 149, 235, 0.4),rgba(80, 149, 235, 0.4)),#fafafa"
      : "#fafafa"};
  cursor: pointer;
  b {
    font-size: 16px;
    margin-right: 5px;
  }
`;

import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { mobileSize } from "../../../util/variables";
import { AppButton, AppText } from "../../ui";
import { ContinueButton } from "./HappinessQuestions";

interface Props {
  text: string;
  handleNext: (answer: string | string[] | undefined) => void;
}

export const TextBreak: React.FC<Props> = ({ text, handleNext }) => {
  const textRef = useRef(null);
  useEffect(() => {
    if (textRef.current) {
      textRef.current.innerHTML = text;
    }
  }, [text]);

  return (
    <Container>
      <AppText ref={textRef} id="text-break" style={{ lineHeight: "30px" }} />
      <MarginDiv>
        <ContinueButton onClick={() => handleNext(undefined)}>Continue</ContinueButton>
      </MarginDiv>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-height: 100vh;
  height: 500px;
  width: 100%;
  padding-bottom: 40px;
  padding-top: 128px;
  padding-left: 20px;
  padding-right: 20px;
  @media ${mobileSize} {
    padding-top: 48px;
    padding-left: 0px;
    padding-right: 0px;
    height: calc(100vh - 126px); // screen_height - progress_bar_height
  }
`;

const MarginDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 48px;
  @media ${mobileSize} {
    margin-top: 0px;
  }
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Logo } from "../../../content/assets/imprint";
import { trackEvent } from "../../../services/mixpanel/mixpanel";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";

import { ImprintOnboardingProgressBar } from "../Segments";
import { useOnboardingContext } from "../onboarding/OnboardingContext";
import { AppText } from "../../ui";
import { Answers } from "./Answers";
import { navigate } from "gatsby";
import { fbq } from "../../../services/pixel";

export type AnswerType =
  | "select"
  | "multiselect"
  | "boolean"
  | "quote"
  | "progress"
  | "text"
  | "input"
  | "chart"
  | "chart2"
  | "transition";

export type questionType = {
  question?: string;
  answer_type: AnswerType;
  answers?: string[];
  answer_key?: string;
  subtext?: string;
  assets?: string[];
  conditional_question?: {
    condition: string;
    data: questionType;
  };
};

export const HappinessQuestions = ({ data }) => {
  const [questions, setQuestions] = useState<questionType[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentQuestion, setCurrentQuestion] = useState<questionType>();
  const { onboardingAnswers, addOnboardingAnswer, onboardingLandingPage } = useOnboardingContext();

  useEffect(() => {
    const NUM_ANSWERED_QUESTIONS = 18;
    if (questions && Object.keys(onboardingAnswers).length === NUM_ANSWERED_QUESTIONS) {
      setCurrentIndex(questions.length - 1);
    } else {
      setCurrentIndex(0);
    }
  }, [questions]);

  useEffect(() => {
    const trackProperties = generateProperties(undefined);
    trackEvent("Web_Happiness_Onboarding_Questions_Start", trackProperties);
    setQuestions(data);
  }, [data]);

  useEffect(() => {
    setCurrentQuestion(questions[currentIndex]);
  }, [currentIndex, questions]);

  // useEffect(() => {
  //   if (questions.length > 0 && currentIndex + 1 >= questions.length) {
  //     // When the user gets to the last screen, save the UTM data and onboarding answers
  //     saveUserData(onboardingAnswers, onboardingLandingPage);
  //   }
  // }, [questions, currentIndex]);

  /**
   * Generates the properties used for Mixpanel
   * This is based on the question structure in imprintQuestions.ts
   */
  const generateProperties = (answer: string | string[] | undefined) => {
    const properties: any = {};
    if (currentQuestion?.question) {
      const question = currentQuestion.subtext
        ? `${currentQuestion.question} ${currentQuestion.subtext}`
        : currentQuestion.question;
      properties.question = question;
    } else {
      properties.question = currentQuestion?.answers[0];
    }

    if (answer) {
      properties.answer = answer;
    }

    return properties;
  };

  const handleNextButton = (answer?: string | string[]) => {
    const trackProperties = generateProperties(answer);
    trackEvent("Web_Happiness_Onboarding_Questions_SubmitAnswer", trackProperties);

    const nextIndex = currentIndex + 1;
    console.log("SUBMIT", answer);
    if (currentQuestion?.answer_key) {
      addOnboardingAnswer({ [currentQuestion?.answer_key]: answer });
    }

    if (questions.length > 0 && nextIndex >= questions.length) {
      // Questions completed
      fbq("track", "AddToWishlist", {
        currency: "USD",
        value: 150,
        content_name: "Happiness_Course",
      });
      trackEvent("Web_Happiness_Onboarding_Questions_Completed");
      navigate("/imprint/checkout", {
        state: {
          noReturn: true,
        },
      });
      return;
    }

    if (!!answer && currentQuestion?.conditional_question?.condition === answer) {
      setCurrentQuestion(currentQuestion?.conditional_question.data);
    } else {
      setCurrentIndex(nextIndex);
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container>
      <Div>
        <LogoImg src={Logo} />
        <ImprintOnboardingProgressBar
          childrenLength={questions.length}
          currentIndex={currentIndex}
        />
      </Div>
      {questions && currentQuestion && (
        <QuestionContainer type={currentQuestion.answer_type}>
          {currentQuestion.question && (
            <TextContainer type={currentQuestion.answer_type}>
              <AppText fontWeight={700} textAlign="center">
                {currentQuestion.question}
              </AppText>
              {currentQuestion.subtext && (
                <AppText textAlign="center">{currentQuestion.subtext}</AppText>
              )}
            </TextContainer>
          )}
          <Answers
            answer_type={currentQuestion.answer_type}
            answers={currentQuestion.answers ?? []}
            assets={currentQuestion.assets}
            handleNext={handleNextButton}
          />
        </QuestionContainer>
      )}
    </Container>
  );
};

const Container = styled.section`
  width: 100%;
  min-height: 100vh;
  font-family: ${theme.PRIMARY_FONT};
  background-color: #fefefe;
  @media ${mobileSize} {
    margin-bottom: 32px;
  }
`;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  @media ${mobileSize} {
    flex-direction: column;
    padding-top: 24px;
  }
`;

const LogoImg = styled.img`
  position: fixed;
  left: 7%;
  top: 46px;
  height: 24px;
  @media ${mobileSize} {
    position: static;
    top: 0;
    left: 0;
    height: 16px;
  }
`;

type QuestionType = { type: string };

const QuestionContainer = styled.div<QuestionType>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: 800px;
  margin: 0 auto;
  @media ${mobileSize} {
    padding: 0 ${({ type }) => (type === "chart" || type === "chart2" ? 24 : 40)}px;
  }
`;

const TextContainer = styled.div<QuestionType>`
  max-width: ${props => (props.type === "chart" || props.type === "chart2" ? 586 : 384)}px;
`;

interface ButtonProps {
  margin?: string;
  background?: string;
  color?: string;
}

export const ContinueButton = styled.button<ButtonProps>`
  margin: ${({ margin }) => (margin ? margin : 0)};
  padding: 1rem;
  border-radius: 32px;
  background: ${({ background, disabled }) =>
    disabled
      ? "#d7d7d7"
      : background
        ? background
        : `linear-gradient(
          90deg,
          rgba(115, 123, 253, 0.75) 1.68%,
          rgba(50, 123, 210, 0.75) 116.81%
          ),
        #1e85e2`};
  color: ${({ color, disabled }) => (disabled ? "#524D57" : color ?? "#fefefe")};
  width: 343px;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  @media ${mobileSize} {
    position: fixed;
    bottom: 20px;
    width: 80%;
  }
`;

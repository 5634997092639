import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AnswerProps } from "./Answers";
import { QuotationMark } from "../../../content/assets";
import { BooleanAnswer, BooleanAnswerContainer } from "../onboarding/BooleanTypeAnswer";
import { mobileSize } from "../../../util/variables";
import theme from "../../../util/theme";

const ClickState = {
  NONE: "",
  AGREE: "agree",
  DISAGREE: "disagree",
};

export const BooleanTypeAnswer: React.FC<AnswerProps> = ({ choices, handleNext }) => {
  const [clicked, setClicked] = useState(ClickState.NONE);
  const statement = choices[0];
  useEffect(() => setClicked(ClickState.NONE), [choices]);

  const handleAnswerClick = (answer: string) => {
    setClicked(answer);
    setTimeout(() => {
      handleNext(answer);
    }, 150);
  };

  return (
    <AnswersContainer>
      <StatementContainer>{statement}</StatementContainer>
      <BooleanAnswerContainer>
        <BooleanAnswer clicked={clicked === ClickState.AGREE} onClick={() => handleAnswerClick(ClickState.AGREE)}>
          <b>✓ </b> Agree
        </BooleanAnswer>
        <BooleanAnswer
          clicked={clicked === ClickState.DISAGREE}
          onClick={() => handleAnswerClick(ClickState.DISAGREE)}
        >
          <b>✕</b> Disagree
        </BooleanAnswer>
      </BooleanAnswerContainer>
    </AnswersContainer>
  );
};

const AnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: 400px;
`;

const StatementContainer = styled.div`
  position: relative;
  width: 322px;
  margin: 70px 0;
  padding: 58px 34px;
  border: 2px solid ${theme.YELLOW};
  border-radius: 8px;
  background: #ffda6e66;

  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  ::before {
    content: url(${QuotationMark});
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 58px;
    width: 58px;
    aspect-ratio: 1/1;
    padding-top: 6px;
    border-radius: 50%;
    background-color: ${theme.YELLOW};
    transform: translate(-50%, -50%);
  }

  ::after {
    content: url(${QuotationMark});
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 58px;
    width: 58px;
    aspect-ratio: 1/1;
    padding-top: 6px;
    border-radius: 50%;
    background-color: ${theme.YELLOW};
    transform: translate(50%, 50%) rotate(180deg);
  }
  @media ${mobileSize} {
    width: 295px;
    padding: 48px 32px;
  }
`;

import React from "react";
import { BooleanTypeAnswer } from "./BooleanTypeAnswer";
import { AnswerType } from "./HappinessQuestions";
import { MultiSelectAnswer } from "./MultiSelectAnswer";
import { ChartBreak } from "./ChartBreak";
import { SingleSelectAnswer } from "./SingleSelectAnswer";
import { TextBreak } from "./TextBreak";
import { TextInputAnswer } from "./TextInputQuestion";
import { TransitionBreak } from "./TransitionBreak";
import { QuoteBreak } from "./QuoteBreak";
import { LoadingBreak } from "./LoadingBreak";
import { ChartBreak2 } from "./ChartBreak2";

export interface AnswerProps {
  choices: string[];
  handleNext: (answer: string | string[] | undefined) => void;
}

type Props = {
  answer_type: AnswerType;
  answers: string[];
  assets: string[] | undefined;
  handleNext: (answer: string | string[] | undefined) => void;
};

export const Answers: React.FC<Props> = ({ answer_type, answers, assets, handleNext }) => {
  switch (answer_type) {
    case "select":
      return <SingleSelectAnswer choices={answers} handleNext={handleNext} />;
    case "multiselect":
      return <MultiSelectAnswer choices={answers} handleNext={handleNext} />;
    case "boolean":
      return <BooleanTypeAnswer choices={answers} handleNext={handleNext} />;
    case "input":
      return <TextInputAnswer handleNext={handleNext} />;
    case "quote":
      return (
        <QuoteBreak
          quote={answers[0]}
          author={answers[1]}
          date={answers[2]}
          icon={assets ? assets[0] : ""}
          background={assets ? assets[1] : ""}
          handleNext={handleNext}
        />
      );
    case "transition":
      return (
        <TransitionBreak
          text={answers[0]}
          image={assets ? assets[0] : ""}
          handleNext={handleNext}
        />
      );
    case "chart":
      return (
        <ChartBreak
          quote={answers[0]}
          author={answers[1]}
          date={answers[2]}
          image={assets ? assets[0] : ""}
          icon={assets ? assets[1] : ""}
          handleNext={handleNext}
        />
      );
    case "chart2":
      return (
        <ChartBreak2
          quote={answers[0]}
          author={answers[1]}
          date={answers[2]}
          image={assets ? assets[0] : ""}
          icon={assets ? assets[1] : ""}
          handleNext={handleNext}
        />
      );
    case "text":
      return <TextBreak text={answers[0]} handleNext={handleNext} />;
    case "progress":
      return <LoadingBreak handleNext={handleNext} />;
    default:
      return <div>This should not happen</div>;
  }
};

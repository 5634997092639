import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { mobileSize } from "../../../util/variables";

const PHRASES = [
  "Processing answers",
  "Identifying opportunities",
  "Calculating potential benefits",
];

interface Props {
  handleNext: (answer: string | string[] | undefined) => void;
}

export const LoadingBreak: React.FC<Props> = ({ handleNext }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(prev => (prev + 1) % PHRASES.length);
    }, 3500);

    setTimeout(() => {
      handleNext(undefined);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container>
      {/* <TextContainer> */}
      {/* {index !== 0 && <PrevText>{PHRASES[index]}...</PrevText>} */}
      <CurrText>{PHRASES[index]}...</CurrText>
      {/* </TextContainer> */}
      <LoadingBar />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 40px;
  @media ${mobileSize} {
    gap: 0px;
  }
`;

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  10% {
    opacity: 1;
    transform: translateY(0px);
  }

  90% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

const CurrText = styled.p`
  font-size: 16px;
  animation: ${slideIn} 3.5s ease-in-out infinite;
`;

const load = keyframes`
  from {
    width: 8%;
  }

  to {
    width: 100%;
  }
`;

const LoadingBar = styled.div`
  position: relative;
  width: 384px;
  height: 32px;
  border-radius: 30px;
  background-color: #e5e5e5;

  ::before {
    content: "";
    position: absolute;
    left: 0;
    height: inherit;
    width: 20px;
    border-radius: inherit;
    background-color: #5d74e2;
    animation: ${load} 9.5s ease-out forwards;
  }

  @media ${mobileSize} {
    width: 284px;
    height: 22px;
  }
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { trackEvent } from "../../../services/mixpanel/mixpanel";
import theme from "../../../util/theme";
import { AppButton } from "../../ui";
import { CustomCheckbox, MultiCheckBoxInput } from "../onboarding/MultiSelectAnswer";
import { AnswerProps } from "./Answers";
import { ContinueButton } from "./HappinessQuestions";

export const MultiSelectAnswer: React.FC<AnswerProps> = ({ choices, handleNext }) => {
  const [selected, setSelected] = useState([]);
  useEffect(() => setSelected([]), [choices]);

  const handleCheck = (e: any) => {
    const { value } = e.target;
    if (selected.includes(value)) {
      trackEvent("Web_Happiness_Onboarding_Questions_DeselectAnswer");
      setSelected(selected.filter(item => item !== value)); //uncheck item
    } else {
      trackEvent("Web_Happiness_Onboarding_Questions_SelectAnswer");
      setSelected([...selected, value]); //check item
    }
  };

  return (
    <AnswersContainer>
      {choices.map(choice => (
        <MultiAnswer key={choice} selected={selected.includes(choice)}>
          <MultiCheckBoxInput>
            <input
              type="checkbox"
              value={choice}
              checked={selected.includes(choice)}
              onChange={handleCheck}
            />
            <CustomCheckbox />
          </MultiCheckBoxInput>
          {choice}
        </MultiAnswer>
      ))}
      <ContinueButton
        disabled={selected.length === 0}
        margin="48px 0 0 0"
        onClick={() => handleNext(selected)}
      >
        Next
      </ContinueButton>
    </AnswersContainer>
  );
};

const AnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

type SelectedProp = {
  selected: boolean;
};

const MultiAnswer = styled.label<SelectedProp>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 100%;
  border: 2px solid ${props => (props.selected ? theme.SELECTED_BORDER_COLOR : "#d2d5d8")};
  border-radius: 8px;
  box-sizing: border-box;

  font-weight: 600;
  text-align: center;

  background: ${props => (props.selected ? theme.SELECTED_COLOR : theme.WHITE_COLOR)};
  cursor: pointer;
  transition: all 0.2s;

  @media screen and (min-width: 800px) {
    :hover {
      background: ${props => (props.selected ? theme.SELECTED_COLOR : theme.HOVER_COLOR)};
      border-color: ${props => (props.selected ? theme.SELECTED_BORDER_COLOR : "transparent")};
    }
  }

  :active {
    background: ${theme.ACTIVE_COLOR};
  }
`;
